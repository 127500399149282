<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Customer Information Configurator</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="loaders.form"
            style="margin-left: 10px;"
        ></v-progress-circular>
        <v-btn class="ml-2" small color="success" :loading="loaders.saveAll" :disabled="loaders.form" @click="saveAll">Save All</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form :disabled="loaders.form">
          <h3>Hard-coded Fields</h3>
          <b>Not recommended to change these values. Some are permanently disabled to not break the system.</b>
          <v-row v-if="data.globals?.customerInformationConfiguration?.hardCodedFields" class="mb-2">
            <v-col v-for="(field, f) of Object.keys(data.globals.customerInformationConfiguration.hardCodedFields)" :key="f" lg="6">
              <v-card outlined class="d-flex flex-column mt-2">
                <v-card-text>
                  <span class="d-flex flex-row flex-wrap align-center">
                    <v-text-field outlined dense hide-details label="Label" v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].label" class="mr-2 mb-2"/>
                    <v-text-field outlined dense hide-details label="Input Type" v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].type" disabled class="mr-2 mb-2" append-icon="mdi-information-variant-circle" @click:append="openInputTypeDialog(data.globals.customerInformationConfiguration.hardCodedFields[field])"/>
                    <v-checkbox label="Visible?"  v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].visible"  class="mr-2"/>
                    <v-checkbox label="Required?"  v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].required" />
                  </span>
                  <span class="d-block mb-2">
                    <span class="d-flex flex-row">
                      <h3>Attributes</h3>
                      <v-btn class="ml-2" color="info" x-small @click="openAddAttributeDialog(data.globals.customerInformationConfiguration.hardCodedFields[field])"><v-icon>mdi-plus</v-icon> Attribute</v-btn>
                    </span>
                    <v-card outlined v-for="(attr, a) of Object.keys(data.globals.customerInformationConfiguration.hardCodedFields[field].attributes)" :key="a" class="mt-2">
                      <v-card-text class="d-flex flex-row justify-space-between align-center mb-2">
                        <b style="width: 70px;">{{attr}}</b>
                        <span class="d-flex flex-column flex-fill">
                          <v-text-field class="mb-1" dense outlined v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].attributes[attr].value" hide-details label="Value"/>
                          <v-text-field v-if="attr==='items'" class="mb-1" dense outlined v-model="data.globals.customerInformationConfiguration.hardCodedFields[field].attributes[attr].route" hide-details label="Data Route"/>
                        </span>
                        <v-btn class="ml-2" color="error" x-small fab @click="deleteAttribute(data.globals.customerInformationConfiguration.hardCodedFields[field], attr)"><v-icon>mdi-close</v-icon></v-btn>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <hr>
          <span class="d-flex flex-row align-center my-2">
            <h3>Additional Fields</h3>
            <v-btn fab color="info" x-small class="ml-2" @click="addOptionalField"><v-icon>mdi-plus</v-icon></v-btn>
          </span>
          <v-row v-if="data.globals?.customerInformationConfiguration?.optionalFields">
            <v-col v-for="(field, f) of data.globals.customerInformationConfiguration.optionalFields" :key="f" lg="6">
              <v-card outlined class="d-flex flex-column mt-2">
                <v-card-text>
                  <span class="d-flex flex-row flex-wrap align-center">
                    <v-text-field outlined dense hide-details label="Label" v-model="field.label"/>
                    <v-text-field class="ml-2" outlined dense hide-details label="Input Type" v-model="field.type" append-icon="mdi-information-variant-circle" @click:append="openInputTypeDialog(field)"/>
                    <v-checkbox class="ml-2" label="Visible?" v-model="field.visible"/>
                    <v-checkbox class="ml-2" label="Required?" v-model="field.required"/>
                    <v-text-field class="ml-2" outlined dense hide-details label="Metadata Key" v-model="field.metadataKey"/>
                  </span>
                  <span class="d-block mb-2">
                    <span class="d-flex flex-row">
                      <h3>Attributes</h3>
                      <v-btn class="ml-2" color="info" x-small @click="openAddAttributeDialog(field)"><v-icon>mdi-plus</v-icon> Attribute</v-btn>
                    </span>
                    <v-card outlined v-for="(attr, a) of Object.keys(field.attributes)" :key="a" class="mt-2">
                      <v-card-text class="d-flex flex-row justify-space-between align-center mb-2">
                        <b style="width: 70px;">{{attr}}</b>
                        <span class="d-flex flex-column flex-fill">
                          <v-text-field class="mb-1" dense outlined v-model="field.attributes[attr].value" hide-details label="Value"/>
                          <v-text-field v-if="attr==='items'" class="mb-1" dense outlined v-model="field.attributes[attr].route" hide-details label="Data Route"/>
                        </span>
                        <v-btn class="ml-2" color="error" x-small fab @click="deleteAttribute(field, attr)"><v-icon>mdi-close</v-icon></v-btn>
                      </v-card-text>
                    </v-card>
                  </span>
                  <span>
                    <v-btn color="error" small @click="removeOptionalField(f)">Delete</v-btn>
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="addAttributeDialog.isOpen" width="650">
      <v-card>
        <v-card-title>Add New Attribute</v-card-title>
        <v-card-text>
          <v-text-field outlined label="Attribute" v-model="addAttributeDialog.data.name"/>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeAddAttributeDialog" color="error">Cancel</v-btn>
          <v-btn @click="addAttribute" :disabled="!addAttributeDialog.data.name" color="success">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="inputTypeDialog.isOpen" width="800">
      <v-card>
        <v-card-title>Input Types</v-card-title>
        <v-card-text class="d-flex flex-wrap">
          <v-btn @click="setInputType('v-text-field')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VTextField / v-text-field</v-btn>
          <v-btn @click="setInputType('v-checkbox')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VCheckbox / v-checkbox</v-btn>
          <v-btn @click="setInputType('v-textarea')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VTextarea / v-textarea</v-btn>
          <v-btn @click="setInputType('v-btn')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VBtn / v-btn</v-btn>
          <v-btn @click="setInputType('v-autocomplete')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VAutocomplete / v-autocomplete</v-btn>
          <v-btn @click="setInputType('date')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">Date Picker - v-text-field</v-btn>
          <v-btn @click="setInputType('v-combobox')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VCombobox / v-combobox</v-btn>
          <v-btn @click="setInputType('v-file-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VFileInput / v-file-input</v-btn>
          <v-btn @click="setInputType('v-date-picker')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VDatePicker / v-date-picker</v-btn>
          <v-btn @click="setInputType('v-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VInput / v-input</v-btn>
          <v-btn @click="setInputType('v-otp-input')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VOtpInput / v-otp-input</v-btn>
          <v-btn @click="setInputType('v-overflow-btn')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VOverflowBtn / v-overflow-btn</v-btn>
          <v-btn @click="setInputType('v-radio')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VRadio / v-radio</v-btn>
          <v-btn @click="setInputType('v-slider')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSlider / v-slider</v-btn>
          <v-btn @click="setInputType('v-range-slider')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VRangeSlider / v-range-slider</v-btn>
          <v-btn @click="setInputType('v-select')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSelect / v-select</v-btn>
          <v-btn @click="setInputType('v-switch')" text outlined class="ma-1" style="text-transform: none; flex: 1 0 45%;">VSwitch / v-switch</v-btn>
        </v-card-text>
        <v-card-actions class="pl-5 pb-5">
          <v-btn text @click="closeInputTypeDialog" color="error">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import utils from "../../plugins/helpers"

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      isCreate: true,

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      requiredBool: true,

      data: {
        globals: {}
      },
      validForm: false,

      loaders: {
        form: false,
        saveAll: false
      },

      taxTypes: [],

      addAttributeDialog: {
        isOpen: false,
        field: null,
        data: {
          name: ""
        }
      },
      restrictedAttributes: ['label', 'v-model'],

      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Hidden', value: 'hidden' },
        { text: 'Transactions', value: 'numTransactions' }
      ],

      inputTypeDialog: {
        isOpen: false,
        field: null
      },
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try {
      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }
  },
  methods: {
    async fetchData(){
      try{
        this.loaders.form = true;

        let res = await axios.get(`${this.getEndpoint}/api/customerInformationConfigurator`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data

        console.log(this.data)

        if(!this.data?.globals?.customerInformationConfiguration?.hardCodedFields){
          this.data.globals.customerInformationConfiguration.hardCodedFields = {};
          let root = this.data.globals.customerInformationConfiguration.hardCodedFields

          root.name = {
            label: 'Name',
            type: 'v-text-field',
            visible: true,
            required: true,
            disabled: true,
            attributes: {
              class: {
                route: '',
                value: ''
              },
              style: {
                route: '',
                value: ''
              }
            }
          }

          root.phone = {
            label: 'Phone',
            type: 'v-text-field',
            visible: true,
            required: true,
            disabled: true,
            attributes: {
              class: {
                route: '',
                value: ''
              },
              style: {
                route: '',
                value: ''
              }
            },
          }

          root.email = {
            label: 'Email',
            type: 'v-text-field',
            visible: true,
            required: true,
            disabled: false,
            attributes: {
              type: 'email',
              class: {
                route: '',
                value: ''
              },
              style: {
                route: '',
                value: ''
              }
            }
          }

          root.address = {
            label: 'Address',
            type: 'custom',
            visible: true,
            required: true,
            disabled: false,
            attributes: {
              class: {
                route: '',
                value: ''
              },
              style: {
                route: '',
                value: ''
              }
            }
          }
        }
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    async saveAll(){
      try{
        this.loaders.form = true;

        console.log("saving", this.data)

        for(let key of Object.keys(this.data.globals)){
          if(typeof this.data.globals[key]!=='boolean' && !Array.isArray(this.data.globals[key])){
            if(this.data.globals[key]===null || this.data.globals[key]===undefined || this.data.globals[key].length===0){
              throw `Value ${key} cannot be empty`;
            }
          }
        }

        let res = await axios.put(`${this.getEndpoint}/api/customerInformationConfigurator/saveAll`, {data: this.data})
        if(res.data.error) throw res.data.error

        this.snack(`All saved!`, "success");

        await this.fetchData();
      }
      catch (e) {
        console.error(e)
        this.snack(e.msg || e, "error");
      }
      finally {
        this.loaders.form = false;
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    addOptionalField(){
      if(!this.data.globals){
        this.data.globals = {};
      }
      if(!this.data.globals.customerInformationConfiguration){
        this.data.globals.customerInformationConfiguration = {};
      }
      if(!this.data.globals.customerInformationConfiguration.optionalFields){
        this.data.globals.customerInformationConfiguration.optionalFields = [];
      }

      this.data.globals.customerInformationConfiguration.optionalFields.push({
        label: '',
        type: '',
        visible: true,
        required: true,
        metadataKey: '',
        attributes: {
          type: {
            route: '',
            value: ''
          },
          class: {
            route: '',
            value: ''
          },
          style: {
            route: '',
            value: ''
          },
          outlined: {
            route: '',
            value: ''
          },
          dense: {
            route: '',
            value: ''
          }
        }
      });

      this.$forceUpdate();
    },
    removeOptionalField(i){
      this.data.globals?.customerInformationConfiguration?.optionalFields?.splice(i, 1);
      this.$forceUpdate();
    },
    openAddAttributeDialog(field){
      this.addAttributeDialog.isOpen = true;
      this.addAttributeDialog.field = field;
    },
    closeAddAttributeDialog(){
      this.addAttributeDialog.isOpen = false;
      this.addAttributeDialog.data = {name: ""};
      this.addAttributeDialog.field = null;
    },
    addAttribute(){
      if(!this.addAttributeDialog.field) throw "No field to add attribute to."
      if(!this.addAttributeDialog.data.name?.trim()) throw "No name for attribute"
      if(!this.addAttributeDialog.field.attributes){
        this.addAttributeDialog.field.attributes = {};
      }

      if(Object.hasOwn(this.addAttributeDialog.field.attributes, this.addAttributeDialog.data.name.toLowerCase())) throw "This attribute already exists for this field."

      if(this.restrictedAttributes.includes(this.addAttributeDialog.data.name.toLowerCase())) throw "This attribute is not allowed. "

      this.addAttributeDialog.field.attributes[this.addAttributeDialog.data.name.toLowerCase()] = {value: '', route: ''};

      this.closeAddAttributeDialog();

      this.snack('Attribute added')
    },
    deleteAttribute(filter, attr){
      delete filter.attributes[attr];
      this.$forceUpdate();
    },
    closeInputTypeDialog(){
      this.inputTypeDialog.isOpen = false;
      this.inputTypeDialog.field = null;
    },
    openInputTypeDialog(field){
      this.inputTypeDialog.field = field;
      this.inputTypeDialog.isOpen = true;
    },
    setInputType(type){
      this.inputTypeDialog.field.type = type;
      this.inputTypeDialog.isOpen = false;
      this.$forceUpdate()
    },
  }
}
</script>
